<template>
  <div class="row">
    <div class="col-12 col-lg-3 g c">
        <div class="card shadow xx" @click="$router.push('/forwards')">
            <div class="card-header text-center">
                <h1 class="text-center c">
                    <i class="fa fa-share"></i>
                </h1>
            </div>
            <div class="card-body text-center">
                المحول للوكيل
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g c">
        <div class="card shadow xx" @click="$router.push('/forwards2')">
            <div class="card-header text-center">
                <h1 class="text-center c">
                    <i class="fa fa-reply"></i>
                </h1>
            </div>
            <div class="card-body text-center">
                المحول للموجه
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g c">
        <div class="card shadow xx" @click="$router.push('/_lessons/report')">
            <div class="card-header text-center">
                <h1 class="text-center c">
                    <i class="fa fa-pie-chart"></i>
                </h1>
            </div>
            <div class="card-body text-center">
                تقارير الطلاب
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 g c">
        <div class="card shadow xx" @click="$router.push('/archive')">
            <div class="card-header text-center">
                <h1 class="text-center c">
                    <i class="fa fa-archive"></i>
                </h1>
            </div>
            <div class="card-body text-center">
                ارشيف الرسائل
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    created(){
        if(!checkPer("lessons")){
            this.$router.push('/NotPermitted')
            return false;
        }
    }
}
</script>

<style>
.xx:hover{
    background: "#ddd" !important;
    border: 2px solid "#555" !important;
    border-width: 2px;
}
</style>